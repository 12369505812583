import { useState } from 'react'
import { Disclosure, Dialog } from '@headlessui/react'
import { Link, useParams } from "react-router-dom";
import { Bars3Icon, XMarkIcon, MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const topnavigation = [
  { name: 'Cheats', to: '/cheats' },
  { name: 'TOS', to: '/tos' },
  { name: 'Status', to: '/status' },
  { name: 'FAQ', to: '/faq' },
  { name: 'Discord', to: '/discord' },
]
// Create a new script element
var script = document.createElement('script');

// Set the src attribute for the script
script.src = 'https://cdn.sell.app/embed/script.js';

// Set the type attribute for the script
script.type = 'module';

// Append the script to the document (usually to the head or body)
document.head.appendChild(script);

// Create a new link element for the stylesheet
var link = document.createElement('link');

// Set the href attribute for the link
link.href = 'https://cdn.sell.app/embed/style.css';

// Set the rel attribute for the link
link.rel = 'stylesheet';

// Append the link to the document head
document.head.appendChild(link);

const products = [
  {
    id: 39771,
    name: 'EFT Private',
    category: 'EFT',
    to: '/Product/39771',
    price: '$11.99',
    imageSrc: 'https://storage.sell.app/store/3001/listings/7h9cVM2BDtr4jOyX8mVANeBiZYX3QTrgCvfdvmYv.png',
    desc:
      `▸ CPU: INTEL and AMD
      ▸ Win Ver: Win10 2004+ through Win11 22H2
      
      Aimbot
      
      ▸ Silent aim
      ▸ FOV adjustment
      ▸ Multiple aimbot keys
      ▸ Aimbot prediction
      ▸ Vis check
      
      Player ESP
      
      ▸ Teammate check
      ▸ Skeleton
      ▸ Health
      ▸ Distance
      ▸ SCAV filter
      ▸ Corpse
      ▸ Level
      
      Item ESP
      
      ▸ Boxes
      ▸ Valuables
      ▸ Quest items
      ▸ Containers
      ▸ Boxes
      ▸ All loot
      ▸ see loot inside containers
      
      Misc
      
      ▸ Third person
      ▸ No visor
      ▸ Thermal vision (custom colored)
      ▸ No spread
      ▸ Streamer mode (turns all blantant features off)
      ▸ Aspect ratio changer
      ▸ Night vision
      
      Exploits
      
      ▸ Instant bullet (risky)
      ▸ Instant search (safe)
      ▸ Instant examine (safe)
      ▸ Loot through walls (safe)`,
    status: 'Online & Undetected'
  },
  {
    id: 24226,
    name: 'Plus EFT',
    category: 'EFT',
    to: '/Product/24226',
    price: '$14.99',
    imageSrc: 'https://storage.sell.app/store/3001/listings/kY4vjCbFKEGN1c7gVqMmH5o3FgrsWGnSPwBMRW6g.png',
    desc:
      `Reported EFT glow | Best legit cheat

      Requirements:
      
      [+] AMD / INTEL CPU
      [+] ALL WINDOWS 10 VERSIONS
      [+] UNIQUE BUILD GENERATION
      
      Features:
      
      [+] Player chams
      [+] Visible check
      [+] No recoil
      [+] No sway
      [+] Infinite stamina
      [+] Double search`,
    status: 'Online & Undetected'
  },
  
  {
    id: 77290,
    name: 'Terra ABS EFT',
    category: 'EFT',
    to: '/Product/77290',
    price: '$7.99',
    imageSrc: 'https://storage.sell.app/store/3001/listings/8Bq06QegymNAm5BFe1Nq3qsdXPPZp0GYcqq9DIMB.png',
    desc:
      `Reported EFT external

      Requirements: 
      
      [+] Windows 10 and windows 11 except windows 11 22h2
      [+] AMD / INTEL CPUs supported
      
      Features:
      
      AIMBOT
      
      [+] Toggle button
      [+] Toggle FOV button
      [+] FOV slider
      [+] Custom aim hotkey
      [+] Max distance slider
      
      VISUALS
      
      [+] Toggle button
      [+] Box ESP
      [+] Healthbar 
      [+} Distance ESP
      [+] Skeleton ESP (laggy for bad pcs)
      [+] Max distance
      [+] Loot ESP
      [+] Loot ESP distance 
      
      Misc
      
      [+] Panic button`,
      status: 'Online & Undetected'
  },

{
  id: 32414,
  name: 'Reported Apex',
  category: 'Apex',
  to: '/Product/32414',
  price: '$4.99',
  imageSrc: 'https://storage.sell.app/store/3001/listings/XlkRmLZMoE6DU7q9SZakJJR17Y0X8P7DDIJLzQoW.png',
  desc:
    `▸ Aimbot, you can choose if you want to run it or not and select an aimkey for it.
    ▸ Randomized aim movement (no straight line aiming like 100% of all other aimbots do)
    ▸ Smoothing, to make your aim look more human
    ▸ Aimdelay, to slow down the aimsteps and again look more human
    ▸ Triggerbot (auto shoot if target is in crosshair), you can choose the trigger key
    ▸ No recoil, you can choose the amount of recoil reduction
    ▸ Visibility check
    ▸ Ignore downed
    ▸ Aim prediction
    ▸ Bone selection
    ▸ Lock target (or not)
    ▸ Controller support
    ▸ Optional second aim key
    ▸ 3rd Person view
    ▸▸▸ Automatically changes to normal view when you aim
    ▸ External overlay
    ▸▸▸ Streamproof
    ▸▸▸ Videorecord proof
    ▸▸▸ Screenshot proof
    ▸▸▸ Name ESP
    ▸▸▸ TEAM ESP
    ▸▸▸ TOTAL HP NUMBER (HEALTH + SHIELD)
    ▸▸▸ HP BAR
    ▸▸▸ SHIELD BAR
    ▸▸▸ DISTANCE ESP
    ▸▸▸ LINES TO ENEMIES
    ▸▸▸ BOX ESP
    ▸▸▸ BONE ESP
    ▸▸▸ AIM FOV CIRCLE
    ▸▸▸ TRIGGER FOV CIRCLE
    ▸▸▸ RENDER DISTANCE SLIDER
    ▸▸▸ FONT SELECTION
    ▸▸▸ FONT SIZE SELECTION
    ▸▸▸ RENDER REFRESH RATE SLIDER (can be lowered on slow computers)
    ▸▸▸ Visbility check`,
  status: 'Online & Undetected'
},
{
  id: 39775,
  name: 'Reported Apex Glow',
  category: 'Apex',
  to: '/Product/39775',
  price: '$2.49',
  imageSrc: 'https://storage.sell.app/store/3001/listings/dr4p4QEQg01fy5XuYoTdPCqsGhRbJM1P9jn8fjKy.png',
  desc:
    `▸ Glow with visibility check, you can select the color
    ▸▸▸ Friend color
    ▸▸▸ Enemy knocked color
    ▸▸▸ Enemy color + visible
    ▸▸▸ Enemy color + in-visible
    ▸▸▸ Enemy low hp + visible
    ▸▸▸ Enemy low hp + in-visible
    ▸ External Menu`,
  status: 'Online & Undetected'
},

 
  {
    id: 35483,
    name: 'Rust Private',
    category: 'Rust',
    to: '/Product/35483',
    price: '$9.99',
    imageSrc: 'https://storage.sell.app/store/3001/listings/9sF2eCZzGr9aecqkcxEFJ91blHeucitJdMl3hi8y.png',
    desc:
      `Lastcheat Rust
      [ Visuals Tab ]
      
      [+] Player/Scientist ESP
      [+] Show Teammates
      [+] Show Wounded
      [+] Show Sleepers
      [+] Name
      [+] Health/Healthbar
      [+] Distance
      [+] Team Number
      [+] SteamID
      [+] Image Based Hotbar
      [+] Text Based Hotbar
      [+] Aim Direction
      [+] Corner/Full Boxes
      [+] Skeleton
      [+] Snaplines
      [+] Offscreen Indicators
      [+] Chams
      
      [ Aimbot Tab ]
      
      [+] Hotkey
      [+] Smoothing
      [+] Draw FOV
      [+] Keep Target
      [+] Target Teammates
      [+] FOV Slider
      [+] Hitbox Selector
      [+] Ignore List (Players, Sleepers, Wounded, Scientists)
      
      [+] Silent Aim*
      [+] Hotkey
      [+] Draw FOV
      [+] Auto Shoot
      [+] Target Teammates
      [+] Aim Line
      [+] FOV Slider
      [+] Hitbox Selector
      [+] Ignore List (Players, Sleepers, Wounded, Scientists)
      
      Weapon Mods
      [+] Recoil Slider
      [+] Spread Slider
      [+] Sway Slider
      [+] Fire rate Slider
      [+] Thickness Slider
      [+] Bullet Speed Slider
      [+] Heli Hitbox Expander
      [+] Bullet Tracers
      [+] Instant Eoka
      [+] Reload Indicator
      [+] Full Auto
      [+] Shoot Through Teammates
      [+] Shoot Through Wounded
      [+] Penetrate/Ignore Materials(Shoot through certain materials such as cargo ship)
      [+] Weapon Spam(Always On/On Hotkey)
      [+] Custom Hitsound
      [+] Custom Hit Distance
      [+] Custom Hit Box Override
      
      Rage
      [+] Desync
      [+] Desync Hotkey
      [+] Show Desync Indicator
      [+] Stop Movement
      [+] Instant Shoot(Shoots max amount of bullets within the same milisecond)
      [+] Powershot(Waits until max desync then shoots max amount of bullets)
      
      [ Movement ]
      
      [+] Anti-Flyhack Bar
      [+] Omni Sprint
      [+] Can Attack Always
      [+] Can Hold Items Always
      [+] On Ladder Animation
      [+] Walk On Water
      [+] Walk Through Players
      [+] Walk Through Trees
      [+] No Fall Damage
      [+] Infinite Jump
      [+] High Jump
      [+] Spiderman
      [+] Speedhack
      
      Visual Mods
      [+] Bullet Spectate
      [+] Custom Ambient
      [+] Custom Time
      [+] No Weapon Bobbing
      [+] No Weapon Lowering
      [+] No Weapon Lerping
      [+] No Firing Animations
      [+] Crosshair
      [+] Custom FOV
      [+] Zoom FOV(On Hotkey)
      
      Silent Mods
      [+] Auto Pickup
      [+] Keep Alive All(Keeps Downed Player Alive)(Forces Them To F1 Kill)
      [+] Instant Revive Teammates
      [+] Instant Revive Target
      [+] Auto Upgrade
      [+] Auto Heal
      [+] Follow Leader
      [+] Spinbot
      [+] Silent Melee
      [+] Silent Farm
      
      [ Misc ]
      
      [+] Admin Flags
      [+] Safe Admin Commands
      [+] Instant Loot
      [+] Suicide(Always On/On Hotkey with Spam Rate)
      [+] Interactive Debug
      [+] SteamID Copier
      [+] Toggle Friend
      [+] Streamproof
      
      [ Settings ]
      
      [+] Font Shadows
      [+] Font Distance Scaling
      [+] Custom Font
      [+] Custom ESP Colors
      
      Experimental
      [+] Radar
      [+] Raid ESP
      [+] Disable Nearby Recyclers
      [+] Disable Nearby Furnaces
      [+] Disable Nearby Campfires
      [+] Animations
      [+] Stash Unhider
      
      Object ESP
      [+] Ores
      [+] Collectibles
      [+] Animals
      [+] Helicopters
      [+] Vehicles
      [+] Boats
      [+] Construction
      [+] Crates
      [+] Barrels
      [+] Loot(Dropped Items, Corpses)
      [+] Custom Tab(You Can Add Custom Objects To ESP And Save Them To Your Config)`,
    status: 'Online & Undetected'
  },
 

  {
    id: 39780,
    name: 'Kool R6',
    category: 'R6',
    to: '/Product/39780',
    price: '$4.99',
    imageSrc: 'https://storage.sell.app/store/3001/listings/zLVp3NpkbP3SvTfmoSoqQFxxWH1zn8GHowjmWBey.png',
    desc:
      `[ AIM ]
      - Aim assist
      - Aim assist smoothing 
      - Draw FOV 
      
      [ ESP ]
      - Skeleton ESP
      - Health ESP
      - Toggle Teammates
      - Toggle Bots
      - Visibility Check
      - Customizable Colors
      
      [ MISC ]
      - Status Icon
      - Streamproof (OBS, Streamlabs, etc)
      - HWID swoofer Built in`,
    status: 'Online & Undetected'
  },
  {
    id: 28890,
    name: 'Reported R6',
    category: 'R6',
    to: '/Product/214414',
    price: '$11.99',
    imageSrc: 'https://storage.sell.app/store/3001/listings/wYN2gcH6y7CUvFk2LHbYlZ8Q2BUKREXCC4jTLX2m.png',
    desc:
      `System requirements:

      AMD / INTEL CPU
      WINDOWS 10 ALL / WINDOWS 11 (sometimes does not work on windows 11 22h2)
      
      
      AIMBOT
      
      - Toggle
      - Smoothing
      - FOV
      - Hitbox
      - Aimkey
      
      VISUALS
      
      - Box
      - Name
      - Health
      - Outline
      - Skeleton
      - TeamID
      - Lines
      - Gadget
      
      MISC
      - No recoil
      - Outlines
      - No spread
      - No sky
      - Custom colors for FOV, lines, and skeleton
      - Config system`,
    status: 'Online & Undetected'
  },
  {
    id: 28890,
    name: 'Speedtail R6',
    category: 'R6',
    to: '/Product/214414',
    price: '$11.99',
    imageSrc: 'https://storage.sell.app/store/3001/listings/wYN2gcH6y7CUvFk2LHbYlZ8Q2BUKREXCC4jTLX2m.png',
    desc:
      `System requirements:

      AMD / INTEL CPU
      WINDOWS 10 ALL / WINDOWS 11 (sometimes does not work on windows 11 22h2)
      
      
      AIMBOT
      
      - Toggle
      - Smoothing
      - FOV
      - Hitbox
      - Aimkey
      
      VISUALS
      
      - Box
      - Name
      - Health
      - Outline
      - Skeleton
      - TeamID
      - Lines
      - Gadget
      
      MISC
      - No recoil
      - Outlines
      - No spread
      - No sky
      - Custom colors for FOV, lines, and skeleton
      - Config system`,
    status: 'Online and Undetected'
  },
  {
    id: 37693,
    name: 'Fecurity COD',
    category: 'COD',
    to: '/Product/37693',
    price: '$4.99',
    imageSrc: 'https://storage.sell.app/store/3001/listings/m3qFFop09dQMenwUdysjDogIcx3EVEq9EsLLvcRQ.png',
    desc:
      `Fecurity
      Requirements
      
      ▸ CPU: INTEL and AMD
      ▸ Win Ver: 1909 - all new (Windows 10 only)(Home and Pro but better use Pro version)
      
      Aimbot
      Enabled Checkbox
      Type (Mouse, Silent) Types of aimbot
      Aim Key Keybind
      Hitbox key keybind
      Ignore Knocked Targets Checkbox
      Style (Bind, Scope, Attack)
      Switch Target Delay Slider ms
      NoRecoil Checkbox
      DrawFov Checkbox
      Prediction Checkbox
      Visible Only Checkbox
      Fov Slider
      Aim Speed Horizontal Slider
      Aim Speed Vertical Slider
      Hitscan
      
      Visuals
      Enabled Checkbox
      Enemy Only Checkbox
      Player Group:
      Visible Only Checkbox
      Auto Enemy Mark Checkbox
      Box Checkbox
      Box Type (Rect, Corner, Rounded Rect) Combobox
      Box Outline Checkbox
      Distance Checkbox
      Weapon Name Checkbox
      Health Bar Checkbox
      Armor Bar Checkbox
      Nicknames Checkbox
      Ping Checkbox
      Rank Checkbox
      Kills Checkbox
      Max Distance Slider
      GlowGroup:
      Glow Checkbox
      Fill Checkbox
      ColorVisible ColorPicker
      ColorInvisible ColorPicker
      Line Width Slider
      Mode (Default, Collapsed, Thermal)
      GlowGroup End
      PlayerGroup End
      LootGroup:
      Loot Enabled Checkbox
      Distance Checkbox
      Cache Delay Slider
      Max Distance Slider
      LootFilter:
      Weapons Checkbox
      Grenades Checkbox
      Ammo Checkbox
      Offhand Checkbox
      Armor Checkbox
      Killstreak Checkbox
      Money Checkbox
      Market Checkbox
      Contracts Checkbox
      LootFilter End
      LootGroup End
      
      Misc:
      Override Fov Checkbox
      Override Fov -> Value Slider
      Juggernaut UAV Checkbox
      Quiet steps Checkbox
      Adjustment Checkbox
      Slide Cancel Checkbox
      Unlocker Group:
      Loadout Future Checkbox
      Weapons Checkbox
      Blueprints Checkbox
      Charms Checkbox
      Stickers Checkbox
      Sights Checkbox
      Attachments Checkbox
      Operators Checkbox
      Replicas Checkbox
      Finishers Checkbox
      UnlockerGroupEnd
      EngineGroup
      Use animations for visuals Checkbox
      Animations speed multiplayer Slider
      DPI Scale -> Slider
      EngineGroupEnd
      Utils Group:
      Reload Fonts Button
      Reload Functions Button
      Detach Software Button
      UtilsGroupEng
      
      Colors
      
      Experimental
      Here we are developing new functions.`,
    status: 'Online & Undetected'
  },
  
   
  {
    id: 47270,
    name: 'CS2 pro',
    category: 'DayZ',
    to: '/Product/47270',
    price: '$7.99',
    imageSrc: 'https://storage.sell.app/store/3001/listings/T61YaxgyNwMULLG2iW9bFR9nJiGtywS8QfnKYbQG.jpg',
    desc:
      `SYSTEM REQUIREMENTS

      [+] Windows 10 all versions
      [+] Windows 11 all versions except 22h2
      [+] AMD / INTEL CPUS
      
      
      AIM 
      
      
      [+] Enable
      [+] Prediction
      [+] Bone selection
      [+] FOV
      [+] FOV rate
      
      
      VISUALS
      
      - Player ESP
      
      [+] Enable
      [+] Box
      [+] Skeleton
      [+] Current item
      [+] Distance
      [+] Corpses
      
      - Infected ESP
      
      [+] Enable
      [+] Distance
      
      - Vehicle ESP
      
      [+] Enable
      [+] Distance
      
      - Animal ESP
      
      [+] Enable
      Distance
      
      - Item ESP
      
      [+] Enable
      [+] Distance
      [+] Quality
      [+] Weapon
      [+] Ammunition
      [+] Attachments
      [+] Clothes
      [+] Food and drinks
      [+] Medical supplies`,
    status: 'Online & Undetected'
  },
  {
    id: 42342,
    name: 'Reported Spoofer',
    category: 'Spoofer',
    to: '/Product/42342',
    price: '$9.99',
    imageSrc: 'https://storage.sell.app/store/3001/listings/4f889e7ac19a59d1e6eb461b8c761ee0.png',
    desc:
      `BattlEye🟢
      🟢 ARK
      🟢 ARMA
      🟢 BLACK SQUAD
      🟢 DAYZ
      🟢 ESCAPE FROM TARKOV
      🟢  FORTNITE
      🟢 RAINBOW SIX SIEGE
      🟢 SUPER PEOPLE
      🟢  PUBG: BATTLEGROUNDS
      🟢 UNTURNED
      🟢 THE CYCLE: FRONTIER
      
      
      
      Easy Anti-cheat
      🟢 APEX LEGENDS
      🟢 VAMPIRE: THE MASQUERADE - BLOODHUNT
      🟢 DEAD BY DAYLIGHT
      🟢  FORTNITE
      🟢 SCUM
      🟡 RUST (possibly works for some people and not for others)
      
      
      
      Call of Duty
      🟢 MW2019
      🟢 Warzone
      🟢 Cold War
      
      
      Vanguard
      🟢Valorant (Most people) `,
    status: 'Online & Undetected'
  },
  
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  let { id } = useParams();

  var productDisplay = {}
  products.map((product) => {
    if(parseInt(product.id) === parseInt(id)) {
      productDisplay = product
    }
  })

  return (
    <div>
      <div className="isolate bg-black">
        <div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]">
          <svg
            className="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
            viewBox="0 0 1155 678"
          >
            <path
              fill="url(#f4773080-2a16-4ab4-9fd7-579fec69a4f7)"
              fillOpacity=".2"
              d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
            />
            <defs>
              <linearGradient
                id="f4773080-2a16-4ab4-9fd7-579fec69a4f7"
                x1="1155.49"
                x2="-78.208"
                y1=".177"
                y2="474.645"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#9089FC" />
                <stop offset={1} stopColor="#FF80B5" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="px-6 pt-6 lg:px-8">
          <nav className="flex items-center justify-between" aria-label="Global">
            <div className="flex lg:flex-1">
              <Link to="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Reported Cheats</span>
                <img className="h-12" src="/reported.png" alt="" />
              </Link>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
              {topnavigation.map((item) => (
                <Link key={item.name} to={item.to} className="text-sm font-semibold leading-6 text-white hover:text-fuchsia-500">
                  {item.name}
                </Link>
              ))}
            </div>
            <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            </div>
          </nav>
          <Dialog as="div" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
            <Dialog.Panel focus="true" className="fixed inset-0 z-10 overflow-y-auto bg-black px-6 py-6 lg:hidden">
              <div className="flex items-center justify-between">
                <Link to="#" className="-m-1.5 p-1.5">
                  <span className="sr-only">Reported Cheats</span>
                  <img className="h-12" src="/reported.png" alt="" />
                </Link>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-400"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/25">
                  <div className="space-y-2 py-6">
                    {topnavigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.to}
                        className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-white hover:bg-gray-400/10"
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                  <div className="py-6">
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </div>
        <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:py-40 lg:px-8">
        <div className="mx-auto max-w-4xl divide-y divide-white/10">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-white">Status:</h2>
          <dl className="mt-10 space-y-6 divide-y divide-white/10">
            {products.map((product) => (
              <Disclosure as="div" key={product.name} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-white">
                        <span className="text-base font-semibold leading-7">{product.name}</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-fuchsia-500">{product.status}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
        </div>
        </div>
        <footer className="bg-black">
            <div className="mx-auto max-w-7xl py-12 px-6 md:flex md:items-center md:justify-between lg:px-8">
                <div className="mt-8 md:order-1 md:mt-0">
                <p className="text-center text-xs leading-5 text-gray-300">
                    &copy; 2024 Reported Cheats, Inc. All rights reserved.
                </p>
                </div>
            </div>
    </footer>
    </div>
  )
}
